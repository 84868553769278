import * as React from "react";
import styled from "styled-components"
import { motion } from "framer-motion"

import Navbar from "./Navbar";
import Footer from "./Footer";


const Wrapper = styled.div`
position: relative;
background: #fff1de;
padding-top: 70px;
max-width:100vw;
@media screen and (min-width: 769px){
  padding-top: 90px;
}

@media screen and (min-width: 1440px){
  max-width: 1920px;
    transform: none;
    margin-left: auto;
    margin-right: auto;
}

`

const TemplateWrapper = ({ children }) => {
  return (
    <Wrapper>
      <Navbar/>
      <motion.main
          initial={{
            opacity: 0,
            x: -200
          }}
          animate={{
            opacity: 1,
            x: 0
          }}
          exit={{
            opacity: 0,
            x: 200
          }}
          transition={{
            type: "spring",
            mass: 0.35,
            stiffness: 75,
            duration: 0.3
          }}
        >
      {children}
      </motion.main>
      <Footer/>
      </Wrapper>
  );
};

export default TemplateWrapper;
