import styled from "styled-components"

import React, { useState } from 'react';

import { motion } from "framer-motion"

import SearchOverlayInnerComp2 from "./SearchOverlay2";

import ToggleSearch from "./ToggleSearch";

import ToggleMenu from "./ToggleMenu";

import { Link } from "gatsby"

import logo from '../../static/img/CrystalLogo.svg'

const menu_variants = {
	menu_open: { opacity: 1, x: "0" },
	menu_closed: { opacity: 0, x: "100vw"},
}

const search_variants = {
	search_open: { opacity: 1, y: "0" },
	search_closed: { opacity: 0, y: "-100vh",},
}

const sight_variants = {
	can_see: { opacity: 1 },
	cannot_see: {opacity: 0},
}



const SiteHeader = styled.header`
width: 100vw;
position: fixed;
top: 0;
left: 0;
background: #fff1de;
z-index: 800;

`
const SiteHeaderInner = styled.div`
display: -webkit-flex;
display: flex;
-webkit-align-items: center;
align-items: center;
justify-content: space-between;
padding: 0 3%;

@media screen and (min-width: 769px){
    height: 90px;
}

@media screen and (max-width: 768px) and (min-width: 351px){
    height: 70px;
}

`

const SiteLogo = styled(Link)`
display: flex;
position: relative;
text-decoration: none;
color: #303C3C;
width:25%;
@media screen and (min-width: 769px){
    width: 255px;
    height: 60px;
	max-width: 255px;
}

@media screen and (max-width: 670px) and (min-width: 151px){
    width: 185px;
    height: 44px;
	max-width: 185px;
}
`


const Menu = styled.aside`
height: 100vh;
position: absolute;
top: 0;
right: 0;
background: #303C3C;
z-index: 1;
opacity:0;

&:before{
	content: "";
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background: #303C3C;

	@media screen and (max-width: 768px) and (min-width: 351px){
		height: 70px;
	}
}

}

@media screen and (max-width: 1440px) and (min-width: 671px){
    width: 425px;
}
@media screen and (max-width: 670px){
    width: 100%;
}
`
const MenuOverlay = styled.div`
opacity: 0;
transition: opacity ease .6s;
width: 100vw;
height: 100vh;
position: absolute;
top: 0;
right: 100%;
background: rgba(255,255,255,0.7);
z-index: -1;

@media screen and (max-width: 670px) {
	display: none!important;
	opacity: 0!important;
}
`

const MenuInner = styled.div`
height: 100%;
display: -webkit-flex;
display: flex;
-webkit-flex-direction: column;
flex-direction: column;
overflow: auto;

@media screen and (max-width: 1024px) and (min-width: 671px){
    padding: 100px 50px 80px;
}

@media screen and (max-width: 670px){
    padding: 100px 3% 150px;
}

@media screen and (min-width: 1025px){
    padding: 100px 50px 50px;
}
`

const SiteMenu = styled.ul`
list-style: none;


`

const MenuItem = styled.li`
padding-bottom: 10px;
border-bottom: 2px solid white;
font-size: 1rem;
line-height: 180%;

&:nth-child(n+2){
    padding-top: 18px;
}
`

const MenuItemTitle = styled(Link)`
color: white;
font-size: 1.375rem;
font-family: "Anton", "Impact", Impact;
font-weight: 400;
line-height: 120%;
text-transform: uppercase;
text-decoration: none;
@media screen and (max-width: 670px){
    
    font-size: 1.125rem;
}

`

const MenuSignup = styled.div`
@media screen and (max-width: 1920px) and (min-width: 671px){
    padding-top: 40px;
}

@media screen and (max-width: 670px){
    padding-top: 30px;
}

@media screen and (max-width: 670px){
    padding-bottom: 100px;
}

margin-top: auto;
color: white;
`

const MenuSignupForm = styled.div`

`

const MenuSignupFormTitle = styled.h6`
color: white;
font-family: "Anton", "Impact", Impact;
font-weight: 400;
line-height: 120%;
text-transform: uppercase;
font-size: 1.375rem;

@media screen and (max-width: 670px){
    
    font-size: 1.125rem;
}
`

const MenuSignupFormText = styled.p`
margin-top: 15px;
color: white;
`

const MenuSignupFormAjax = styled.form`
position: relative;
margin-top: 15px;
`

const MenuSignupFormAjaxInput = styled.input`
background: none;
color: white;
padding-right: 70px;
padding-left: 15px;
transition: border-color ease .4s;
height: 50px;
font-size: 1rem;
width: 100%;
display: block;
padding-top: 3px;
border: 1px solid white;
line-height: normal;
border-radius: 0;
-webkit-appearance: none;
`

const MenuSignupFormAjaxButton = styled.button`
@media screen and (min-width: 671px){
    right: 20px;
}
font-family: "Anton", "Impact", Impact;
font-weight: 400;
text-transform: uppercase;
line-height: 120%;
display: inline-block;
cursor: pointer;
border: none;
text-align: center;
color: white;
border-radius: 0;
-webkit-appearance: none;
position: absolute;
top: 50%;
-webkit-transform: translateY(-50%);
-webkit-transform: translateY(-50%);
-ms-transform: translateY(-50%);
transform: translateY(-50%);
padding: 0;
background: none;
width: 26px;
right: 20px;
margin-top: 0;
`

const MenuSignupFormAjaxSVG = styled.svg`
height: auto;
display: block;
overflow: visible;
width: 26px;
`

const ToggleMenuWrapper = styled.a`
width: 40px;
height: 48px;
display: block;
position: relative;
z-index: 300;
opacity: 1;
visibility: visible;
transition: opacity ease .4s;
text-decoration: none;
color: black;
`

const SearchOverlay = styled.aside`
height: 100vh;
    transition: height cubic-bezier(0.72, 0.19, 0.36, 1) 0.6s;
	width: 100%;
    position: absolute;
    top: 0;
    left: 0;
	opacity:0;
    background-position: top center;
    overflow: scroll;
	background: #fff url("/img/pattern-tile-dark.svg") center/475px repeat;
`

const LogoSvg = styled.img`
@media screen and (min-width: 769px){
    width: 255px;
    height: 60px;
	max-width: 255px;
}

@media screen and (max-width: 768px) and (min-width: 351px){
    width: 185px;
    height: 44px;
	max-width: 185px;
}
`



const Navbar = () => {
    const [searchIsOpen, setSearchIsOpen] = useState(false);

    const [menuIsOpen, setMenuIsOpen] = useState(false);

    return (
    <>
    <SiteHeader>
	<SiteHeaderInner>
		<ToggleSearch handleClick={() => setSearchIsOpen(!searchIsOpen)}>
		</ToggleSearch>

		<SiteLogo to="/" aria-label="Site Logo">
			<LogoSvg src={logo} alt="Incredible Art"></LogoSvg>
		</SiteLogo>

		<ToggleMenuWrapper 
			aria-label="Toggle menu" href="javascript:void(0)" 
			as={motion.a}
			animate={searchIsOpen ? "cannot_see" : "can_see"}
			variants={sight_variants}
			transition={{ duration: .4 }}
			onClick={() => setMenuIsOpen(!menuIsOpen)}> 
			
		<ToggleMenu
		/>

		 </ToggleMenuWrapper>
		<Menu 
			as={motion.aside}
			animate={menuIsOpen ? "menu_open" : "menu_closed"}
      		variants={menu_variants}
			transition={{ duration: .65 ,  ease: [0.73, 0.32, 0.33, 1.02] }}
		>
			<MenuOverlay
			as={motion.div}
			animate={menuIsOpen ? "menu_open" : "menu_closed"}
			variants={menu_variants}
			transition={{ duration: .2 }}
			></MenuOverlay>
			<MenuInner>
				<SiteMenu>
					<MenuItem>
                        <MenuItemTitle to="/buying-guides">Buying Guides</MenuItemTitle>
                    </MenuItem>
					<MenuItem>
                        <MenuItemTitle to="/crystal-facts">Crystal Facts</MenuItemTitle>
                    </MenuItem>
					<MenuItem>
                        <MenuItemTitle to="/common-questions">Common Questions</MenuItemTitle>
                    </MenuItem>
				</SiteMenu>
				<MenuSignup>
					<MenuSignupForm>
						<MenuSignupFormTitle>Newsletter</MenuSignupFormTitle>
						<MenuSignupFormText>Sign up for a free monthly newsletter to help you get the most from your crystals.</MenuSignupFormText>
						<MenuSignupFormAjax method="post" netlify-honeypot="bot-field" data-netlify="true" name="contact">
							<MenuSignupFormAjaxInput  type="email" name="EMAIL" placeholder="Enter email…" autocomplete="off" aria-label="Enter email address" placeholderTextColor="white"/>
							<input type="hidden" name="bot-field" />
							<input type="hidden" name="form-name" value="contact" />
							<MenuSignupFormAjaxButton  aria-label="submit">
								<MenuSignupFormAjaxSVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.41 19.41">
									<g id="fbae9a04-7ba6-4a52-9d53-b676ffa219dd" data-name="Layer 2">
										<polygon id="b6d30a43-977e-49d8-b2e0-7b8e92337b96" points="20.71 0 19.29 1.41 26.59 8.71 0 8.71 0 10.71 26.59 10.71 19.29 18 20.71 19.41 30.41 9.71 20.71 0" class="e3614bc1-a667-4a77-8ad5-3967e867c60b" data-name="Layer 1"></polygon>
									</g>
								</MenuSignupFormAjaxSVG>
							</MenuSignupFormAjaxButton>
						</MenuSignupFormAjax>
					</MenuSignupForm>
				</MenuSignup>
			</MenuInner>
		</Menu>
        <SearchOverlay
			as={motion.aside}
			animate={searchIsOpen ? "search_open" : "search_closed"}
      		variants={search_variants}
			transition={{ duration: .65 ,  ease: [0.73, 0.25, 0.27, 0.95] }}
		>
			<SearchOverlayInnerComp2/>
		</SearchOverlay>
	</SiteHeaderInner>
</SiteHeader>
    </>
    )
    }
    
export default Navbar;
