import React from 'react';

import Hamburger from 'hamburger-react'





const ToggleMenu = (props) => { return (
    <>
   		
        <Hamburger onClick={props.handleClick}></Hamburger>
        
    </>
    )
}

export default ToggleMenu