import styled from "styled-components"

import React, { useState } from 'react';

import { motion } from "framer-motion"

const sight_variants = {
	can_see: { opacity: 1, zIndex: "999", display:"inline" },
	cannot_see: {opacity: 0, display: "none"},
}

const ToggleSearchWrapper  = styled.a`
width: 40px;
height: 28px;
display: block;
position: relative;
text-decoration: none;
color: #303C3C;
`

const ToggleSearchOpen =styled.span`
opacity: 1;
visibility: visible;
transition: opacity ease .4s .4s;
display: block;
`

const ToggleSearchOpenSVG =styled.svg`
width: 28px;
height: 28px;
color:black;
`

const ToggleSearchClose =styled.span`
position: absolute;
top: 50%;
left: 50%;
-webkit-transform: translate(-50%, -50%);
transform: translate(-50%, -50%);
opacity:0;
`

const ToggleSearchCloseSVG =styled.svg`
color:black;
width: 28px;
height: 28px;
`	





const ToggleSearch = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
    <>
   <ToggleSearchWrapper aria-label="Toggle menu" href="javascript:void(0)"  onClick={props.handleClick}>
       	<ToggleSearchOpen onClick={() => setIsOpen(!isOpen)}>
				<ToggleSearchOpenSVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.71 29.71">
				<g id="bad92352-3203-41f8-aa90-6aeaf873722d" data-name="Layer 2">
					<path id="f8497ae5-6d01-4a79-8784-1dc2abca57b3" d="m29.71 28.29-8.56-8.55a12 12 0 1 0-1.41 1.41l8.55 8.56zM2 12a10 10 0 1 1 10 10A10 10 0 0 1 2 12z" class="b631791f-027a-4bdd-b29d-bb41915aa60f" data-name="Layer 1"></path>
				</g>
				</ToggleSearchOpenSVG>                       
		</ToggleSearchOpen> 

		<ToggleSearchClose
			as={motion.span}
			animate={isOpen ? "can_see" : "cannot_see"}
			variants={sight_variants}
            onClick={() => setIsOpen(!isOpen)}> 
				<ToggleSearchCloseSVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.7 29.7">
					<g id="a5a346ef-45a9-4122-8f0f-363c52b9293a" data-name="Layer 2">
						<polygon id="e2a9f8e1-728d-4c0d-b9b4-b44c5510f481" points="29.7 1.41 28.28 0 14.85 13.44 1.41 0 0 1.41 13.44 14.85 0 28.28 1.41 29.7 14.85 16.26 28.28 29.7 29.7 28.28 16.26 14.85 29.7 1.41" class="b0f13bc4-af9f-46c3-a673-15a10406f5f0" data-name="Layer 1">
						</polygon>
					</g>
                   </ToggleSearchCloseSVG>  
		</ToggleSearchClose> 
    </ToggleSearchWrapper>
    </>
)
}

export default ToggleSearch