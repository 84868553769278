import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components2/Layout";
import Seo from "../components2/Seo";
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import Facebook from "../../static/img/facebook.png"
import Youtube from "../../static/img/youtube.png"
import Pinterest from "../../static/img/pinterest.png"

const FooterWrapper = styled.footer`
@media screen and (min-width: 769px){
    margin-top: -20px;
}


    margin-top: -20px;
    margin-left: 3%;
    margin-right: 3%;
    margin-top: 2px;
`

const FooterInner = styled.div`
background: #303C3C;
`
const Columns = styled.div`
@media screen and (min-width: 769px){
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
}

@media screen and (max-width: 670px){
    flex-direction:row;
    flex-wrap:wrap;
  }

    display: -webkit-flex;
    display: flex;
`

const ColumnMenu = styled.div`
@media screen and (min-width: 1025px){
    width: 30.8333333333%;
}

@media screen and (min-width: 671px){
    padding-top: 80px;
    padding-bottom: 80px;
}

@media screen and (max-width: 670px){
  padding-top: 50px;
  padding-bottom: 50px;
    order:2;
    width:50%;

    &:nth-child(n+2) {
        order:3;
    }
}

&:nth-child(n+2) {
    border-left: 2px solid #fff1de;
}
padding-left: 5%;
padding-right: 5%;
display: -webkit-flex;
display: flex;
-webkit-align-items: center;
align-items: center;
-webkit-justify-content: center;
justify-content: center;
-webkit-flex-grow: 1;
flex-grow: 1;
`

const ColumnMenuUl = styled.ul`
list-style: none;
text-align: center;
`

const ColumnMenuLi = styled.li`
&:nth-child(n+2) {
    margin-top: 10px;
}
    color: white;
    font-family: "Anton","Arial",sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    line-height: 120%;

@media screen and (min-width: 1025px){
    font-size: 20px;
    font-size: 1.25rem;
}
@media screen and (min-width: 1025px){
    font-size: 24   px;
    font-size: 1.25rem;
}

`

const ColumnMenuAnchor = styled(Link)`
text-decoration: none;
color: white
`

const ColumnSignup = styled.div`
@media screen and (min-width: 769px){
    &:nth-child(n+2) {
        border-left: 2px solid #fff1de;
    }
}

@media screen and (min-width: 1025px){
    width: 30.8333333333%;
}

@media screen and (min-width: 671px){
    padding-top: 80px;
    padding-bottom: 80px;
}

@media screen and (max-width: 670px){
    order:1;
    width:100%;
    padding-top: 50px;
    padding-bottom: 50px;
    border-bottom: 2px solid #fff1de;
}

text-align: center;
display: -webkit-flex;
display: flex;
-webkit-align-items: center;
align-items: center;
-webkit-justify-content: center;
justify-content: center;
-webkit-flex-grow: 1;
flex-grow: 1;
`

const SignupForm = styled.form`
position: relative;
display: flex;
align-items: center;
-webkit-justify-content: center;
justify-content: center;
margin-top: 20px;
`

const SignUpWrapper = styled.div`
@media screen and (min-width: 769px){
    max-width: 410px;
}
width: 80%;
`

const SignUpTitle = styled.h6`

@media screen and (min-width: 1025px){
    font-size: 42px;
    font-size: 2.625rem;
}
@media screen and (min-width: 1025px){
    font-size: 26px;
    font-size: 1.625rem;
}

color: white;
font-family: "Anton","Arial",sans-serif;
font-weight: 400;
line-height: 120%;
text-transform: uppercase;
`

const SignUpText = styled.p`
&:nth-child(n+2) {
    margin-top: 20px;
}
@media screen and (min-width: 1025px){
    font-size: 16px;
    font-size: 1rem;
}
@media screen and (min-width: 1025px){
    font-size: 18px;
    font-size: 1.125rem;
}

color: white;
line-height: 180%; 
`

const SignUpInput = styled.input`
@media screen and (min-width: 671px){
    padding-right: 70px;
}

@media screen and (min-width: 671px){
    height: 50px;
}

@media screen and (max-width: 670px){
    height: 42px;
}
    font-size: 16px;
    font-size: 1rem;
    width: 100%;
    display: block;
    padding-top: 3px;
    background: none;
    border: 1px solid #303C3C;
    font-family: "Epilogue","Arial",sans-serif;
    line-height: normal;
    border-radius: 0;
    -webkit-appearance: none;
    padding-left: 15px;
    padding-right: 15px;
    transition: border-color ease .4s;
    background: none;
    border-color: white;
    color: white;
}
`

const SignUpButton =styled.button`
@media screen and (min-width: 671px){
    right: 20px;
    height: 50px;
}

@media screen and (max-width: 670px){
    height: 42px;
}
background:none;
display: -webkit-flex;
-webkit-align-items: center;
display: flex;
align-items: center;
-webkit-justify-content: center;
justify-content: center;
-webkit-appearance: none;
position: absolute;
top: 50%;
-webkit-transform: translateY(-50%);
-webkit-transform: translateY(-50%);
-ms-transform: translateY(-50%);
transform: translateY(-50%);
width: 32px;
right: 20px;
`

const SocialBar = styled.div`
&:nth-child(n+2){
    border-top: 2px solid #fff1de;
}

&:nth-child(n+2){
    border-top: 2px solid #fff1de;  
}

@media screen and (max-width: 670px){
  padding-top: 20px;
  padding-bottom: 20px;
}

    padding-left: 10%;
    padding-right: 10%;
`

const SocialUl = styled.ul`
@media screen and (min-width: 671px){
    padding-top: 30px;
    padding-bottom: 30px;
}

    max-width: 1330px;
    width: 94%;
    margin-right: auto;
    margin-left: auto;
    -webkit-justify-content: center;
    justify-content: center;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    flex-direction:row;
`

const SocialLi = styled.li`
@media screen and (min-width: 1025px){
    font-size: 18px;
    font-size: 1.125rem;
}
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-flex-grow: 1;
    flex-grow: 1;
    margin: 0;
    list-style: none;
    line-height: 180%;
`

const SocialAnchor = styled.a`
    color: white;
    transition: opacity ease .4s;
    text-decoration: none;
`

const TinyPNG = styled.img`
height: 30px;
width: 30px;
`

const AllRightsWrapper = styled.div`
font-size: .75rem;
text-align:center;
padding-top: 40px;
padding-bottom: 40px;
`

const AllRights = styled.p`
text-align:center;
line-height: 180%;
`




const Footer = ({}) => {
    return(
        <FooterWrapper>
            <FooterInner>
                <Columns>
                    <ColumnMenu>
                        <ColumnMenuUl>
                        <ColumnMenuLi><ColumnMenuAnchor to="/about">About</ColumnMenuAnchor></ColumnMenuLi>
                        <ColumnMenuLi><ColumnMenuAnchor to="/blog">All Articles</ColumnMenuAnchor></ColumnMenuLi>
                        <ColumnMenuLi><ColumnMenuAnchor to="/">Contact</ColumnMenuAnchor></ColumnMenuLi>
                        </ColumnMenuUl>
                    </ColumnMenu>
                    <ColumnSignup>
                        <SignUpWrapper>
                        <SignUpTitle>Newsletter</SignUpTitle>
                        <SignUpText>Sign up for a free monthly newsletter to help you get the most from your crystals.</SignUpText>
                        <SignupForm  action="" >
                            <SignUpInput type="email" name="EMAIL" placeholder="Enter email…" autocomplete="off" aria-label="Enter email address" />
                            <SignUpButton aria-label="submit">
                                <svg style={{height:'1.5em'}}><g id="fbae9a04-7ba6-4a52-9d53-b676ffa219dd" data-name="Layer 2"><polygon id="b6d30a43-977e-49d8-b2e0-7b8e92337b96" points="20.71 0 19.29 1.41 26.59 8.71 0 8.71 0 10.71 26.59 10.71 19.29 18 20.71 19.41 30.41 9.71 20.71 0" class="e3614bc1-a667-4a77-8ad5-3967e867c60b" data-name="Layer 1"></polygon></g></svg>
                            </SignUpButton>
                        </SignupForm >
                        </SignUpWrapper>
                    </ColumnSignup>
                    <ColumnMenu>
                        <ColumnMenuUl>
                        <ColumnMenuLi><ColumnMenuAnchor href="#" >Privacy</ColumnMenuAnchor></ColumnMenuLi>
                        <ColumnMenuLi><ColumnMenuAnchor href="#" >Terms</ColumnMenuAnchor></ColumnMenuLi>
                        <ColumnMenuLi><ColumnMenuAnchor href="#" >Advertise</ColumnMenuAnchor></ColumnMenuLi>
                        </ColumnMenuUl>
                    </ColumnMenu>
                </Columns>
                <SocialBar>
                    <SocialUl>
                        <SocialLi>
                        <SocialAnchor href="#" target="_blank" aria-label="Facebook" >
                            <TinyPNG src={Facebook} alt="Incredible Art"></TinyPNG>
                        </SocialAnchor>
                        </SocialLi>
                        <SocialLi>
                        <SocialAnchor href="#" target="_blank" aria-label="Pinterest" >
                            <TinyPNG src={Pinterest} alt="Incredible Art"></TinyPNG>
                        </SocialAnchor>
                        </SocialLi>
                        <SocialLi>
                        <SocialAnchor href="#" target="_blank" aria-label="Youtube" >
                            <TinyPNG src={Youtube} alt="Incredible Art"></TinyPNG>
                        </SocialAnchor>
                        </SocialLi>
                    </SocialUl>
                </SocialBar>
            </FooterInner>
            <AllRightsWrapper>
                <AllRights >@ 2022 CharmedCrystal.com.<br/>All rights reserved.</AllRights>
            </AllRightsWrapper>
        </FooterWrapper>
)}
export default Footer