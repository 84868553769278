import styled from "styled-components"
import { useStaticQuery, graphql, Link } from 'gatsby'
import React, {useState} from 'react';
import { useFlexSearch } from 'react-use-flexsearch';

import { GatsbyImage } from "gatsby-plugin-image";
import { motion, AnimatePresence } from "framer-motion"
const SearchOverlayInner = styled.div`
width: 94%;
margin-right: auto;
margin-left: auto;
padding-top: 75px;


@media screen and (max-width: 1920px){
    max-width: 870px;
}
`

const SearchForm = styled.form`
position: relative;
`

const SearchFormInput = styled.input`
background: #fff;
padding-right: 70px;
font-size: 1.1875rem;
height: 70px;
padding-left: 30px;
width: 100%;
display: block;
padding-top: 3px;
border: 1px solid #303C3C;
font-family: "Epilogue","Arial",sans-serif;
line-height: normal;
`

const SearchFormButton = styled.button`

`
const SearchFormSVG = styled.svg`

`

const GoodReadsSection = styled.section`
@media screen and (max-width: 1920px) and (min-width: 671px){
    padding-bottom: 80px;
}
`

const GoodReadsSectionWrapper = styled.div`
width: 100%;
@media screen and (max-width: 1920px){
    max-width: 870px;
}
margin-right: auto;
margin-left: auto;
`

const GoodReadsSectionTitle = styled.h4`
display: -webkit-flex;
display: flex;
-webkit-align-items: center;
align-items: center;
color: #C24C92;

@media screen and (min-width: 1025px){
    
    font-size: 3.25rem;
}

@media screen and (max-width: 1024px){
    
    font-size: 2.625rem;
}


@media screen and (max-width: 1920px) and (min-width: 671px){
    margin-bottom: 30px;
}

font-family: "Anton", "Impact", Impact;
font-weight: 400;
line-height: 120%;
text-transform: uppercase;


&:after {
	content: "";
    min-width: 50px;
    height: 2px;
    -webkit-flex-grow: 1;
    flex-grow: 1;
    justify-self: flex-end;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    margin-left: auto;
    background: #C24C92;
	@media screen and (min-width: 671px)
	{
    max-width: 65.4166666667%;
	}
}
`

const GoodReadsSectionTitleSpan = styled.span`
margin-right: 30px;

`

const PostList = styled.ul`
list-style: none;
@media screen and (min-width: 671px){
    display: grid;
    grid-template-columns: 50% 50%;
}
`

const PostListItem = styled.li`
line-height: 180%;


@media screen and (min-width: 671px){
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    margin-bottom: 5%;
    flex-direction: column;
}

@media screen and (min-width: 1025px){
    
    font-size: 1.125rem;
}
`

const PostListItemThumbnail = styled(Link)`
@media screen and (min-width: 671px){
    width: 90%;
}

text-decoration: none;
color: #303C3C;
position: relative;
display: block;
overflow: hidden;
`

const PostListItemThumbnailImg = styled(GatsbyImage)`
width: 100%;
display: block;
max-width: 100%;
height: auto;
`

const PostListItemTextBox = styled.div`

margin-top: 1%;
margin-bottom: 3.75%;
@media screen and (min-width: 671px){
    width: 90%;
    -webkit-flex-grow: 1;
    flex-grow: 1;
    margin-top: 3.75%;
    margin-bottom: 0;
}

`

const PostListItemTextBoxTitle = styled.span`
@media screen and (max-width: 1920px) and (min-width: 671px){
    margin-bottom: 15px;
	font-size: .875rem;
}
display: inline-block;
border-bottom: 2px solid transparent;
font-size: .75rem;
font-family: "Epilogue","Arial",sans-serif;
font-weight: 700;
color: #C24C92;
text-transform: uppercase;
letter-spacing: .1em;
`

const PostListItemTextBoxText = styled.p`

`

const PostListItemTextBoxTextLink = styled(Link)`
text-decoration: none;
color: #303C3C;
`

const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      
      transition: {
        staggerChildren: 0.3
      }
    }
  };

  const listItem = {
    hidden: { 
        opacity: 0, 
        transition:{ ease:"easeOut", duration: .15 }
    },
    show: { 
        opacity: 1,
        transition:{ ease:"easeIn", duration: .65 }
    },
  };



    const SearchOverlayInnerComp2 = (props) => {
        const queryData = useStaticQuery(graphql`
        { 
            localSearchPages {
            index
            store
            }
            allMdx(filter: {frontmatter: {templateKey: {eq: "blog-post"}}}) {
                edges {
                    node {
                      id
                      slug
                      frontmatter {
                        title
                        tags
                        featuredimage {
                          childImageSharp {
                            gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH, sizes: "(max-width: 1000px) 200px, 40vw")
                          }
                        }
                      }
                    }
                  }
            }
          }
        `)
        const index = queryData.localSearchPages.index
        const store = queryData.localSearchPages.store
        

        const [query, setQuery] = useState('')
        const results = useFlexSearch(query, index, store)
          
        const posts = queryData.allMdx.edges

          
    return (
    <>
			<SearchOverlayInner>
				<SearchForm method="get" action="javascript:void(0);">
					
                    <SearchFormInput 
                    type="text" 
                    placeholder="Type to start searching!" 
                    value={query}
                    onChange={(event) => setQuery(event.target.value)} 
                    name="s" autocomplete="off" 
                    aria-label="Type to start searching!"/>
				</SearchForm>
               
				<GoodReadsSection>
					<GoodReadsSectionWrapper>
                    <GoodReadsSectionTitle>
                        <GoodReadsSectionTitleSpan>Search Results</GoodReadsSectionTitleSpan>
                        </GoodReadsSectionTitle>
                        {results.length > 0 ? (
                    <PostList
                    variants={container} 
                    initial="hidden" 
                    animate="show"
                    as={motion.ul}>
                        <AnimatePresence> 
                    {results && results.slice(0, 4).map((result) => (
                        <PostListItem
                        as={motion.li}
                        variants={listItem}
                        key={result.slug}
                        initial="hidden"
                        animate="show"
                        exit="hidden"
                        >
                            <PostListItemThumbnail to={`/${result.slug}`}> 
                                <PostListItemThumbnailImg image={result.image}/>
                                </PostListItemThumbnail>
                            <PostListItemTextBox>
                                <PostListItemTextBoxTitle>{result.tags[0]}</PostListItemTextBoxTitle>
                                <PostListItemTextBoxText> 
                                <PostListItemTextBoxTextLink to={`/${result.slug}`}>
                                {result.title}                                
                                </PostListItemTextBoxTextLink> 
                                </PostListItemTextBoxText>
                            </PostListItemTextBox>
                        </PostListItem>
                    ))}
                     </AnimatePresence>
                    </PostList>
                ) : (
                    <p>Start Your Search!</p>
                )}
						<GoodReadsSectionTitle>
                        <GoodReadsSectionTitleSpan>Good Reads</GoodReadsSectionTitleSpan>
                        </GoodReadsSectionTitle>
						<PostList>
                        {posts &&
                        posts.slice(0, 4).map(({ node: post }) => (
							<PostListItem>
								<PostListItemThumbnail to={`/${post.slug}`}> 
                                    <PostListItemThumbnailImg image={post.frontmatter.featuredimage.childImageSharp.gatsbyImageData}/>
									</PostListItemThumbnail>
								<PostListItemTextBox>
                                    <PostListItemTextBoxTitle>{post.frontmatter.tags[0]}</PostListItemTextBoxTitle>
									<PostListItemTextBoxText> 
                                    <PostListItemTextBoxTextLink to={`/${post.slug}`}>
                                    {post.frontmatter.title}                                
                                    </PostListItemTextBoxTextLink> 
                                    </PostListItemTextBoxText>
								</PostListItemTextBox>
							</PostListItem>
                        ))}
						</PostList>
					</GoodReadsSectionWrapper>
				</GoodReadsSection>
			</SearchOverlayInner>
    </>
    )
    }
    
export default SearchOverlayInnerComp2